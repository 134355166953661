/* eslint-disable no-useless-escape */

import { ValidationRule, Validator } from "~/types/shared";

const urlValidator = {
  validator: (val: any, allowNull = true) => {
    if (allowNull && val === null) {
      return true;
    } else if (val == null) {
      return false;
    }
    const urlRegex =
      /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/;
    return urlRegex.test(val);
  },
  message: `Url invalid. Please check if url starts with https://`,
};

const emailValidator = {
  validator: (val: any) => {
    const urlRegex =
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    return urlRegex.test(val);
  },
  message: `Email invalid.`,
};

const phoneNumberValidator = {
  validator: (val: any) => {
    const urlRegex =
      /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
    return urlRegex.test(val);
  },
  message: `Phone number invalid.`,
};

const requiredValidator = {
  validator: (val: any) => {
    if (val == null || val === "") {
      return false;
    }
    return true;
  },
  message: `This field is required.`,
};

const decimalValidator = {
  validator: (val: any) => {
    const decimals = "*";
    const separator = ".";

    if (val === null || val === undefined || val === "") {
      return false;
    }
    if (Number(decimals) === 0) {
      return /^-?\d*$/.test(val);
    }
    const regexPart = decimals === "*" ? "+" : `{1,${decimals}}`;
    const regex = new RegExp(
      `^[-+]?\\d*(\\${separator}\\d${regexPart})?([eE]{1}[-]?\\d+)?$`,
    );

    return regex.test(val);
  },
  message: `Not a valid decimal number (e.g. 1.2).`,
};

const minValidator = {
  validator: (val: any, min = 1) => {
    if (val != null && val >= min) {
      return true;
    }

    return false;
  },
  message: `Value is too small.`,
};

const maxValidator = {
  validator: (val: any, max = 24) => {
    if (val != null && val <= max) {
      return true;
    }

    return false;
  },
  message: `Value is too large.`,
};

const minLengthValidator = {
  validator: (val: any, min = 6) => {
    if (val != null && val.toString().length >= min) {
      return true;
    }

    return false;
  },
  message: `Input is too short.`,
};

const checkboxValidator = {
  validator: (val: any) => {
    if (val === true) {
      return true;
    }

    return false;
  },
  message: `Checkbox must be checked.`,
};

const validationRuleToValidator: Record<ValidationRule, Validator> = {
  url: urlValidator,
  email: emailValidator,
  phoneNumber: phoneNumberValidator,
  required: requiredValidator,
  decimal: decimalValidator,
  min: minValidator,
  max: maxValidator,
  minLength: minLengthValidator,
  checkbox: checkboxValidator,
};

export const useValidation = () => {
  const validate = <T>(val: T, rule: ValidationRule) => {
    const validator = validationRuleToValidator[rule];
    const isValid = validator.validator(val);
    const errorMessageMaybe = isValid ? null : validator.message;
    return [isValid, errorMessageMaybe] as const;
  };

  return {
    validate,
  };
};
